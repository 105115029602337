import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Helmet from "react-helmet";
import tw, { styled } from "twin.macro";

import EquipmentOwnerSvg from "../../static/images/equipment-owners.svg";
import ExpertsSvg from "../../static/images/experts.svg";
import MaterialOwnerSvg from "../../static/images/material-owners.svg";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout/Layout";

interface TileProps {
  isFull?: boolean;
}

const Tile = styled.article<TileProps>`
  ${tw`p-12 sm:px-14 md:px-24 lg:px-24 -ml-px -mt-px max-w-2xl`}
  ${tw`bg-white border border-grey shadow-lg`}
  ${({ isFull }) => isFull && tw`flex-1`}

  &:nth-of-type(2) {
    ${tw`z-10`}
  }

  h1 {
    ${tw`my-6 uppercase font-bold text-3xl whitespace-pre-wrap`}
  }

  h2 {
    ${tw`mt-2 mb-6 text-xl text-turquoise`}
  }

  svg {
    ${tw`fill-current text-turquoise inline h-24`}
  }
`;

const ThankYou: React.FC = () => {
  const { t, i18n } = useTranslation();
  const redirectURL = i18n.language !== "en" ? "/" + i18n.language + "/" : "/";
  if (typeof window !== "undefined") {
    setTimeout(() => window.location.replace(redirectURL), 8000);
  }

  return (
    <Layout>
      <Helmet title={t("thankYou.header")} />
      <Container tw="pb-32">
        <section tw="flex justify-center mt-32 text-center">
          <Tile>
            <MaterialOwnerSvg />
            <EquipmentOwnerSvg />
            <ExpertsSvg />
            <h1>{t("thankYou.header")}</h1>
            <h2>{t("thankYou.description")}</h2>
          </Tile>
        </section>
      </Container>
    </Layout>
  );
};

export default ThankYou;
